import { NextSeo } from 'next-seo'
import MobileHeroImage from '@/images/illustrations/contactUsHeaderCombo.png'
import DesktopHeroImage from '@/images/illustrations/404Combo.png'
import * as UI from '@/ui'

const Custom404 = () => {
  return (
    <>
      <NextSeo title="404 Page Not Found" description="Fallback for a broken link." />

      <UI.Block gap="xxl" className="mx-auto my-8 max-w-5xl md:my-12">
        <UI.Block gap="xl" className="mb-20">
          <UI.Heading as="h1" size={{ default: 'small', sm: 'medium', md: 'large' }} className="text-center">
            Page not found
          </UI.Heading>

          <UI.Paragraph
            size={{ default: 'medium', md: 'large' }}
            color="black"
            className="mx-auto w-full max-w-4xl text-center md:text-2xl"
          >
            The page you were looking for couldn&apos;t be found. The page could be removed or you misspelled the word
            while searching for it.
          </UI.Paragraph>

          <div className="flex justify-center">
            <UI.Link to="root" type="button">
              Take Me Home
            </UI.Link>
          </div>
        </UI.Block>

        <div className="flex max-w-7xl justify-center">
          <UI.Image src={MobileHeroImage} alt="Mobile Image Combo" className="md:hidden" />
          <UI.Image src={DesktopHeroImage} alt="Desktop Image Combo" className="max-md:hidden" />
        </div>
      </UI.Block>
    </>
  )
}

export default Custom404
